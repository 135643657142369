import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const ScanResultContext = createContext({
  scanResult: {},
  setScanResult: (result) => {},
  clearScanResult: () => {},
});

const initValue = {
  image: '',
  totalScanCount: 0,
  result: [
    {
      confidence: 0,
      identifier: '',
      text: '',
    },
  ],
};

export const ScanResultProvider = (props) => {
  const { children } = props;
  
  const [scanResult, setScanResult] = useState(initValue);
  const [isResult, setIsResult] = useState(false);
  const [totalScanCount, setTotalScanCount] = useState(0);

  const contextValue = useMemo(() => ({
    scanResult,
    isResult,
    totalScanCount,
    setTotalScanCount: (count) => {
      setTotalScanCount(count);
    },
    setScanResult: (result) => {
      setIsResult(true);
      setScanResult(result);
    },
    clearScanResult: () => {
      setIsResult(false);
      setScanResult(initValue);
    },
  }), [scanResult, isResult, totalScanCount]);

  return (
    <ScanResultContext.Provider value={contextValue}>
      {children}
    </ScanResultContext.Provider>
  );
};

ScanResultProvider.propTypes = {
  children: PropTypes.node.isRequired,
};