import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";

export const MultiBarcodeToggle = ({ visible, onChange }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(false);
  }, [setIsActive]);

  return (
    <CheckBoxWrapper visible={visible}>
      <p style={{ color: 'white' }}>Multi Barcode</p>
      <br />
      <CheckBox
        style={{ position: 'relative', margin: 0, padding: 0 }}
        id="checkbox"
        type="checkbox"
        checked={isActive}
        onChange={() => {
          onChange(!isActive);
          setIsActive(!isActive);
        }}
      />
      <CheckBoxLabel htmlFor="checkbox" style={{ position: 'relative', marginLeft: '-34px', padding: 0 }} />
    </CheckBoxWrapper>
  );
};

MultiBarcodeToggle.propTypes = {
  visible: PropTypes.bool,
  onChange: PropTypes.func,
};

const CheckBoxWrapper = styled.div`
  display: ${(props) => props.visible ? 'flex' : 'none'};
  position: absolute;
  height: 50px;
  bottom: 60px;
  right: 12px;
  z-index: 999999;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: #4fbe79;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;
