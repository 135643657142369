import demoConfig from '../demo.config';

const originalUseCases = [
  { id: 'tin', text: 'Tire Identification Number (DOT)', isBeta: false },
  { id: 'tire_id', text: 'Commercial Tire ID', isBeta: false },
  { id: 'tire_size', text: 'Tire Size Specifications', isBeta: false },
  { id: 'meter', text: 'Meter Analog/Digital', isBeta: false },
  { id: 'dialmeter', text: 'Meter Dial', isBeta: false },
  { id: 'ocr', text: 'Serial Number', isBeta: false },
  { id: 'legacy_barcode', text: 'Barcode', isBeta: false },
  { id: 'universalid_dl_at_de', text: 'AT/DE Driving License', isBeta: false },
  { id: 'universalid_es_it_pt', text: 'ES/IT/PT Driving License', isBeta: false },
  { id: 'lpt', text: 'License Plate (EU)', isBeta: false },
  { id: 'lpt_us', text: 'License Plate (US)', isBeta: false },
  { id: 'vin', text: 'Vehicle Identification Number', isBeta: false },
  { id: 'universalid_mrz', text: 'ID MRZ', isBeta: false },
  { id: 'barcode_pdf417_parsed', text: 'ID PDF417', isBeta: false },
  { id: 'containerVertical', text: 'Container Number (Vertical)', isBeta: false },
  { id: 'container', text: 'Container Number (Horizontal)', isBeta: false },
];

export const useCases = demoConfig.barbarian
  ? originalUseCases.map((useCase) =>
      useCase.id === 'legacy_barcode'
        ? { ...useCase, id: 'all_barcode_formats' }
        : useCase
    )
  : originalUseCases.filter((useCase) => useCase.id !== 'barcode_pdf417_parsed');

export const identifierSort = (sortOrder) => (a, b) =>
  (sortOrder[a.identifier] || a.identifier).localeCompare(
    sortOrder[b.identifier] || b.identifier,
  );

export const indexSort = (sortOrder) => (a, b) => {
  if (sortOrder[a] < sortOrder[b]) {
    return -1;
  }
  if (sortOrder[a] > sortOrder[b]) {
    return 1;
  }
};

export const isValidBrowser = (browserInfo) => {
  return true;
};