import React, { useContext } from 'react';

// export const ScanResultIcons = () => <></>
import styled from 'styled-components';
// import copyIcon from '../icons/copyIcon.png';
// import editIcon from '../icons/editIcon.png';
import { ScanResultContext } from '../context/ScanResultContext';
import { MobileFooterContext } from '../context/MobileFooterContext';
// import copy from 'copy-to-clipboard';
import Button from 'antd/lib/button';

// const ScanResultIcon = styled.div`
//    {
//     display: flex;
//     align-items: center;
//     color: #fff;
//     flex-direction: column;
//     justify-content: space-between;
//     height: 35px;
//     cursor: pointer;
//     font-size: 16px;

//     @media (min-width: 641px) {
//       height: 31px;
//       font-size: 12px;
//     }
//   }
// `;

export const ContinueScanButtom = styled(Button)`
  align-self: center;
  background: #007ACC;
  border-radius: 5px;
  color: white;
  margin-bottom: 12px;
`;

// const StyledIcon = styled.img`
//    {
//     height: 20px;
//   }
// `;

export const ScanResultIcons = () => {
  const { clearScanResult, 
    // isResult, 
    // scanResult 
  } = useContext(
    ScanResultContext,
  );
  const { resetFlags } = useContext(MobileFooterContext);
  return (
    <>
      {/* <ScanResultIcon>
        <StyledIcon
          src={copyIcon}
          alt="Copy"
          onClick={() => {
            if (isResult) {
              alert('Result copied to clipboard');
              copy(
                `${JSON.stringify(scanResult.result)}

              Scanned using Anyline Web SDK`,
                { format: 'text/plain' },
              );
            } else {
              alert(
                'Place the item to scan within the frame and wait for the result.',
              );
            }
          }}
        />
        copy
      </ScanResultIcon> */}
      <ContinueScanButtom
        onClick={() => {
          clearScanResult();
          resetFlags();
        }}
      >
        {/* <StyledIcon src={editIcon} alt="Scan again" /> */}
        Continue scanning
      </ContinueScanButtom>
      {/* <ScanResultIcon>
        <StyledIcon src={shareIcon} alt="Share" />
        share
      </ScanResultIcon> */}
    </>
  );
};
