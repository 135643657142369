import React from "react";
import ReactDOM from "react-dom";
import 'antd/dist/antd.min.css';
import './fonts/ProximaNova-Regular.otf'
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ScanResultProvider } from "./context/ScanResultContext";
import { UseCaseProvider } from "./context/UseCaseContext";
import { MobileFooterProvider } from "./context/MobileFooterContext";
import { ResultExpandProvider } from "./context/ResultExpandContext";
import { ScanProvider } from "./context/ScanContext";
import * as Sentry from '@sentry/react';
// eslint-disable-next-line no-restricted-globals

Sentry.init({
  dsn: 'https://cae74334e3e20bf70cd34511035eb3eb@o446219.ingest.sentry.io/4505624920391680',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <UseCaseProvider>
    <MobileFooterProvider>
      <ScanProvider>
        <ScanResultProvider>
          <ResultExpandProvider>
            <App />
          </ResultExpandProvider>
        </ScanResultProvider>
      </ScanProvider>
    </MobileFooterProvider>
  </UseCaseProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
