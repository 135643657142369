import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isAndroid } from "../services/device.detection";

export const TorchOnSVG = () => (
  <svg width="30" height="30" viewBox="0 0 265 265" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M85.9889 132.645C85.9889 141.877 88.7279 150.902 93.8591 158.577C98.9904 166.252 106.283 172.232 114.815 175.76C123.346 179.288 132.732 180.206 141.786 178.398C150.839 176.589 159.152 172.135 165.673 165.6C172.194 159.065 176.63 150.742 178.418 141.684C180.207 132.627 179.268 123.243 175.721 114.719C172.174 106.196 166.178 98.9159 158.492 93.8016C150.806 88.6872 141.775 85.9681 132.543 85.9884C126.421 85.9884 120.359 87.1959 114.704 89.5419C109.049 91.8879 103.912 95.3262 99.588 99.66C95.2637 103.994 91.8367 109.138 89.5031 114.798C87.1696 120.458 85.9754 126.523 85.9889 132.645Z" fill="white"/>
    <path d="M132.25 61.2236C134.525 61.2236 136.707 60.3199 138.315 58.7112C139.924 57.1025 140.828 54.9207 140.828 52.6457V38.5779C140.828 36.3029 139.924 34.1211 138.315 32.5124C136.707 30.9037 134.525 30 132.25 30C129.975 30 127.793 30.9037 126.184 32.5124C124.576 34.1211 123.672 36.3029 123.672 38.5779V52.6457C123.672 54.9207 124.576 57.1025 126.184 58.7112C127.793 60.3199 129.975 61.2236 132.25 61.2236V61.2236Z" fill="white"/>
    <path d="M194.611 81.9822L204.562 72.2034C206.188 70.5767 207.102 68.3705 207.102 66.0701C207.102 63.7697 206.188 61.5636 204.562 59.9369C202.935 58.3103 200.729 57.3965 198.428 57.3965C196.128 57.3965 193.922 58.3103 192.295 59.9369L182.516 69.8873C181.618 70.6563 180.889 71.6026 180.374 72.6668C179.859 73.731 179.57 74.8902 179.524 76.0715C179.479 77.2529 179.678 78.4309 180.109 79.5317C180.54 80.6324 181.195 81.6322 182.03 82.4681C182.866 83.3041 183.866 83.9582 184.967 84.3895C186.068 84.8207 187.246 85.0198 188.427 84.9741C189.608 84.9285 190.768 84.6392 191.832 84.1243C192.896 83.6094 193.842 82.8801 194.611 81.9822V81.9822Z" fill="white"/>
    <path d="M225.92 123.67H211.852C209.577 123.67 207.395 124.574 205.787 126.182C204.178 127.791 203.274 129.973 203.274 132.248C203.274 134.523 204.178 136.705 205.787 138.313C207.395 139.922 209.577 140.826 211.852 140.826H225.92C228.195 140.826 230.377 139.922 231.986 138.313C233.594 136.705 234.498 134.523 234.498 132.248C234.498 129.973 233.594 127.791 231.986 126.182C230.377 124.574 228.195 123.67 225.92 123.67Z" fill="white"/>
    <path d="M194.611 182.514C193.842 181.616 192.896 180.887 191.832 180.372C190.768 179.857 189.608 179.568 188.427 179.522C187.246 179.476 186.068 179.675 184.967 180.107C183.866 180.538 182.866 181.192 182.03 182.028C181.195 182.864 180.54 183.864 180.109 184.964C179.678 186.065 179.479 187.243 179.524 188.425C179.57 189.606 179.859 190.765 180.374 191.829C180.889 192.894 181.618 193.84 182.516 194.609L192.295 204.559C193.101 205.365 194.057 206.004 195.109 206.439C196.162 206.875 197.289 207.1 198.428 207.1C199.567 207.1 200.695 206.875 201.748 206.439C202.8 206.004 203.756 205.365 204.562 204.559C205.367 203.754 206.006 202.798 206.442 201.745C206.878 200.693 207.102 199.565 207.102 198.426C207.102 197.287 206.878 196.159 206.442 195.107C206.006 194.054 205.367 193.098 204.562 192.293L194.611 182.514Z" fill="white"/>
    <path d="M132.25 203.273C129.975 203.273 127.793 204.177 126.184 205.786C124.576 207.395 123.672 209.576 123.672 211.851V225.919C123.672 228.194 124.576 230.376 126.184 231.985C127.793 233.593 129.975 234.497 132.25 234.497C134.525 234.497 136.707 233.593 138.315 231.985C139.924 230.376 140.828 228.194 140.828 225.919V211.851C140.828 209.576 139.924 207.395 138.315 205.786C136.707 204.177 134.525 203.273 132.25 203.273Z" fill="white"/>
    <path d="M69.8888 182.514L59.9384 192.293C58.3118 193.919 57.3979 196.126 57.3979 198.426C57.3979 200.726 58.3118 202.933 59.9384 204.559C61.565 206.186 63.7712 207.1 66.0716 207.1C68.372 207.1 70.5782 206.186 72.2048 204.559L82.1552 194.609C83.0531 193.84 83.7824 192.894 84.2973 191.829C84.8122 190.765 85.1015 189.606 85.1472 188.425C85.1928 187.243 84.9937 186.065 84.5625 184.964C84.1312 183.864 83.4771 182.864 82.6411 182.028C81.8052 181.192 80.8054 180.538 79.7047 180.107C78.6039 179.675 77.4259 179.476 76.2446 179.522C75.0632 179.568 73.9041 179.857 72.8398 180.372C71.7756 180.887 70.8293 181.616 70.0603 182.514H69.8888Z" fill="white"/>
    <path d="M61.2236 132.248C61.2236 129.973 60.3199 127.791 58.7112 126.182C57.1025 124.574 54.9207 123.67 52.6457 123.67H38.5779C36.3029 123.67 34.1211 124.574 32.5124 126.182C30.9037 127.791 30 129.973 30 132.248C30 134.523 30.9037 136.705 32.5124 138.313C34.1211 139.922 36.3029 140.826 38.5779 140.826H52.6457C54.9207 140.826 57.1025 139.922 58.7112 138.313C60.3199 136.705 61.2236 134.523 61.2236 132.248V132.248Z" fill="white"/>
    <path d="M69.8888 81.9822C70.6578 82.8801 71.6041 83.6094 72.6683 84.1243C73.7325 84.6392 74.8917 84.9285 76.073 84.9741C77.2543 85.0198 78.4324 84.8207 79.5331 84.3895C80.6339 83.9582 81.6336 83.3041 82.4696 82.4681C83.3055 81.6322 83.9597 80.6324 84.3909 79.5317C84.8222 78.4309 85.0212 77.2529 84.9756 76.0715C84.93 74.8902 84.6406 73.731 84.1258 72.6668C83.6109 71.6026 82.8816 70.6563 81.9836 69.8873L72.2048 59.9369C71.3994 59.1315 70.4432 58.4926 69.3909 58.0567C68.3385 57.6208 67.2107 57.3965 66.0716 57.3965C64.9326 57.3965 63.8047 57.6208 62.7523 58.0567C61.7 58.4926 60.7438 59.1315 59.9384 59.9369C58.3118 61.5636 57.3979 63.7697 57.3979 66.0701C57.3979 68.3705 58.3118 70.5767 59.9384 72.2034L69.8888 81.9822Z" fill="white"/>
  </svg>
);

export const TorchOffSVG = () => (
  <svg width="30" height="30" viewBox="0 0 265 265" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M132 61.1476C134.27 61.1476 136.446 60.246 138.051 58.6413C139.656 57.0365 140.557 54.86 140.557 52.5906V38.557C140.557 36.2876 139.656 34.111 138.051 32.5063C136.446 30.9015 134.27 30 132 30C129.731 30 127.554 30.9015 125.95 32.5063C124.345 34.111 123.443 36.2876 123.443 38.557V52.5906C123.443 54.86 124.345 57.0365 125.95 58.6413C127.554 60.246 129.731 61.1476 132 61.1476V61.1476Z" fill="white"/>
    <path d="M194.21 81.8559L204.136 72.1009C205.759 70.4782 206.671 68.2774 206.671 65.9826C206.671 63.6878 205.759 61.487 204.136 59.8643C202.514 58.2417 200.313 57.3301 198.018 57.3301C195.723 57.3301 193.523 58.2417 191.9 59.8643L182.145 69.7905C181.249 70.5576 180.522 71.5016 180.008 72.5632C179.494 73.6249 179.206 74.7812 179.16 75.9597C179.115 77.1381 179.313 78.3133 179.743 79.4114C180.174 80.5094 180.826 81.5067 181.66 82.3407C182.494 83.1746 183.491 83.8271 184.589 84.2573C185.687 84.6875 186.863 84.8861 188.041 84.8406C189.22 84.7951 190.376 84.5064 191.438 83.9928C192.499 83.4792 193.443 82.7517 194.21 81.8559V81.8559Z" fill="white"/>
    <path d="M225.443 123.441H211.41C209.14 123.441 206.964 124.343 205.359 125.948C203.754 127.552 202.853 129.729 202.853 131.998C202.853 134.268 203.754 136.444 205.359 138.049C206.964 139.654 209.14 140.555 211.41 140.555H225.443C227.713 140.555 229.889 139.654 231.494 138.049C233.099 136.444 234 134.268 234 131.998C234 129.729 233.099 127.552 231.494 125.948C229.889 124.343 227.713 123.441 225.443 123.441Z" fill="white"/>
    <path d="M194.21 182.143C193.443 181.248 192.499 180.52 191.438 180.006C190.376 179.493 189.22 179.204 188.041 179.159C186.863 179.113 185.687 179.312 184.589 179.742C183.491 180.172 182.494 180.825 181.66 181.659C180.826 182.493 180.174 183.49 179.743 184.588C179.313 185.686 179.115 186.861 179.16 188.04C179.206 189.218 179.494 190.374 180.008 191.436C180.522 192.498 181.249 193.442 182.145 194.209L191.9 204.135C192.703 204.938 193.657 205.576 194.707 206.011C195.757 206.445 196.882 206.669 198.018 206.669C199.154 206.669 200.28 206.445 201.329 206.011C202.379 205.576 203.333 204.938 204.136 204.135C204.94 203.331 205.577 202.378 206.012 201.328C206.447 200.278 206.671 199.153 206.671 198.017C206.671 196.88 206.447 195.755 206.012 194.705C205.577 193.656 204.94 192.702 204.136 191.898L194.21 182.143Z" fill="white"/>
    <path d="M132 202.852C129.731 202.852 127.554 203.753 125.95 205.358C124.345 206.963 123.443 209.139 123.443 211.409V225.442C123.443 227.712 124.345 229.888 125.95 231.493C127.554 233.098 129.731 233.999 132 233.999C134.27 233.999 136.446 233.098 138.051 231.493C139.656 229.888 140.557 227.712 140.557 225.442V211.409C140.557 209.139 139.656 206.963 138.051 205.358C136.446 203.753 134.27 202.852 132 202.852Z" fill="white"/>
    <path d="M69.7915 182.143L59.8653 191.898C58.2427 193.521 57.3311 195.722 57.3311 198.017C57.3311 200.311 58.2427 202.512 59.8653 204.135C61.488 205.758 63.6888 206.669 65.9836 206.669C68.2784 206.669 70.4792 205.758 72.1019 204.135L82.028 194.209C82.9238 193.442 83.6513 192.498 84.1649 191.436C84.6786 190.374 84.9672 189.218 85.0127 188.04C85.0582 186.861 84.8597 185.686 84.4294 184.588C83.9992 183.49 83.3467 182.493 82.5128 181.659C81.6789 180.825 80.6816 180.172 79.5835 179.742C78.4854 179.312 77.3102 179.113 76.1318 179.159C74.9533 179.204 73.797 179.493 72.7354 180.006C71.6737 180.52 70.7297 181.248 69.9626 182.143H69.7915Z" fill="white"/>
    <path d="M61.1476 131.998C61.1476 129.729 60.246 127.552 58.6413 125.948C57.0365 124.343 54.86 123.441 52.5906 123.441H38.557C36.2876 123.441 34.111 124.343 32.5063 125.948C30.9015 127.552 30 129.729 30 131.998C30 134.268 30.9015 136.444 32.5063 138.049C34.111 139.654 36.2876 140.555 38.557 140.555H52.5906C54.86 140.555 57.0365 139.654 58.6413 138.049C60.246 136.444 61.1476 134.268 61.1476 131.998V131.998Z" fill="white"/>
    <path d="M69.7915 81.8559C70.5586 82.7517 71.5026 83.4792 72.5642 83.9928C73.6258 84.5064 74.7822 84.7951 75.9606 84.8406C77.1391 84.8861 78.3143 84.6875 79.4123 84.2573C80.5104 83.8271 81.5077 83.1746 82.3416 82.3407C83.1756 81.5067 83.8281 80.5094 84.2583 79.4114C84.6885 78.3133 84.8871 77.1381 84.8416 75.9597C84.796 74.7812 84.5074 73.6249 83.9938 72.5632C83.4802 71.5016 82.7526 70.5576 81.8569 69.7905L72.1019 59.8643C71.2984 59.0609 70.3446 58.4235 69.2948 57.9887C68.245 57.5539 67.1199 57.3301 65.9836 57.3301C64.8473 57.3301 63.7222 57.5539 62.6724 57.9887C61.6226 58.4235 60.6688 59.0609 59.8653 59.8643C58.2427 61.487 57.3311 63.6878 57.3311 65.9826C57.3311 68.2774 58.2427 70.4782 59.8653 72.1009L69.7915 81.8559Z" fill="white"/>
    <path d="M66 66L198 198" stroke="white" strokeWidth="18" strokeLinecap="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M88.3788 121.306C87.497 124.927 87.0524 128.65 87.0607 132.395C87.0607 141.605 89.793 150.607 94.9118 158.263C100.031 165.92 107.306 171.885 115.816 175.405C124.327 178.925 133.69 179.84 142.722 178.036C143.377 177.905 144.028 177.76 144.675 177.602L88.3788 121.306ZM164.929 166.819C165.481 166.316 166.022 165.8 166.551 165.27C173.056 158.75 177.481 150.447 179.265 141.412C181.049 132.377 180.113 123.015 176.575 114.513C173.036 106.01 167.055 98.7478 159.387 93.6458C151.72 88.5439 142.711 85.8314 133.501 85.8517C127.394 85.8517 121.347 87.0563 115.706 89.3965C110.065 91.7368 104.94 95.1668 100.627 99.49C100.122 99.9958 99.6296 100.513 99.1498 101.04L164.929 166.819Z" fill="white"/>
  </svg>
);

const Wrapper = styled.div`
  display: ${(props) => props.visible ? 'flex' : 'none'};
  position: absolute;
  right: 32px;
  top: 60px;
  z-index: 99999999;
`;

const Button = styled.button`
  border: 0;
  outline: none;
  height: 50px;
  width: 50px;
  background-color: #0066cc;
  color: rgb(239, 239, 239);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2146000012;
`;

export const FlashButton = ({activateFlash, setIsReset, isReset}) => {
  const [flashState, setFlashState] = useState(false);

  useEffect(() => {
    if (!isReset) {
      setIsReset(true);
      setFlashState(false);
    }
  }, [flashState, isReset, setIsReset])

  return (
    <Wrapper visible={!!isAndroid()}>
      <Button onClick={(e) => {
        if (activateFlash) {
          let newState = !flashState;
          
          try {
            setFlashState(newState);
            activateFlash(newState);
          } catch (error) {
            
          } 
        }
      }}>
      {
        flashState === true
        ? <TorchOnSVG id={'TorchOnSVG'}></TorchOnSVG>
        : <TorchOffSVG id={'TorchOffSVG'}></TorchOffSVG>
      }
      </Button>
    </Wrapper>
  )
};

FlashButton.propTypes = {
  activateFlash: PropTypes.func,
  setIsReset: PropTypes.func,
  isReset: PropTypes.bool,
};
