import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ScanResultIcons } from './ScanResultIcons';
import { ScanResultContext } from '../context/ScanResultContext';
import { UseCaseContext } from '../context/UseCaseContext';
import { MobileFooterContext } from '../context/MobileFooterContext';
import { ResultExpandContext } from '../context/ResultExpandContext';

export const allScannedBarcodesCount = 0;

const ScanResultContainer = styled.div.attrs((props) => ({
  hasResult: props.hasResult,
  hidden: props.hidden || false,
  displayed: props.displayed,
}))`
  background: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 20px;
  margin: 20px auto;
  width: 90%;
  max-width: 600px;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  opacity: ${(props) => (props.hidden ? '0' : '1')};
  transform: ${(props) => (props.hidden ? 'translateY(20px)' : 'translateY(0)')};
  display: ${(props) => (props.displayed && props.hasResult ? 'block' : 'none')};
  max-height: 80vh;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;

  @media (min-width: 1025px) {
    width: 50%;
    font-size: 0.9rem;
  }

  @media (max-width: 1024px) {
    width: 80%;
    font-size: 0.95rem;
  }

  @media (max-width: 600px) {
    padding: 15px;
    font-size: 0.85rem;
  }

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
`;

const ScanResultHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const ScanResultHeaderText = styled.span`
  font-size: 1rem;
  font-weight: 600;

  @media (min-width: 1025px) {
    font-size: 1.1rem;
  }
`;

const ScanResultValue = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: 400;
  color: #fff;
  overflow-wrap: break-word;
  overflow-y: auto;
  max-height: 70vh;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #2c2c2c;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 10px;
    border: 3px solid #2c2c2c;
  }

  scrollbar-color: #ffffff #2c2c2c;
  scrollbar-width: thin;
`;

const ScanResultIconGroup = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media (min-width: 641px) {
    justify-content: flex-end;
    width: auto;
  }
`;

const ScanResultPlaceHolder = styled.span`
  color: #aaa;
  opacity: 0.6;
  font-size: 0.9rem;
  padding: 20px 0 10px;
  text-align: center;
`;

const simpleResultType = {
  meter: false,
  ocr: true,
  eCard: false,
  id: false,
  lpt: false,
  austriandl: false,
  germanid: false,
  tire_size: false
};

const MultiLineResult = (props) => {
  const resultsCopy = JSON.parse(JSON.stringify(props.results));
  const resultObjectKey = Object.keys(resultsCopy).filter((item) => item.endsWith('Result'));
  const prettifyResult = resultsCopy[resultObjectKey];

  if (prettifyResult) {
    delete prettifyResult['fieldConfidences'];
  }

  return <pre>{JSON.stringify(prettifyResult, null, 2)}</pre>;
};

const FilterSimpleResult = (result) => {
  if (result.length) {
    const textResult = result.find((r) => r.identifier === 'text');
    return textResult ? <span style={{ fontSize: '1.5rem', color: 'white' }}>{textResult.text}</span> : null;
  }

  return null;
};

export const ScanResult = (props) => {
  const { resultExpanded, setResultExpanded } = useContext(ResultExpandContext);
  const { scanResult, isResult } = useContext(ScanResultContext);
  const { currentUseCase } = useContext(UseCaseContext);
  const { isExpanded, delayedHidden, delayedShown } = useContext(MobileFooterContext);

  const hasResult = !!isResult;
  const hidden = isExpanded || !delayedShown;
  const displayed = !delayedHidden;

  return (
    <ScanResultContainer hidden={hidden} displayed={displayed} hasResult={hasResult}>
      <ScanResultHeader>
        <ScanResultHeaderText>Scan Result:</ScanResultHeaderText>
        <ScanResultIconGroup>
          <ScanResultIcons />
        </ScanResultIconGroup>
      </ScanResultHeader>
      <ScanResultValue>
        {!isResult && (
          <ScanResultPlaceHolder>
            Place the item to scan within the frame and wait for the result.
          </ScanResultPlaceHolder>
        )}
        {simpleResultType[currentUseCase]
          ? isResult && FilterSimpleResult(scanResult.result)
          : isResult && (
              <MultiLineResult
                useCase={currentUseCase}
                multiBarcodeActive={props.multiBarcodeActive}
                expandResults={() => setResultExpanded(!resultExpanded)}
                results={scanResult.result}
                scanResult={scanResult}
                expanded={resultExpanded}
                allowExpand={currentUseCase !== 'ltp'}
              />
            )}
      </ScanResultValue>
    </ScanResultContainer>
  );
};

ScanResult.propTypes = {
  multiBarcodeActive: PropTypes.bool.isRequired,
};

MultiLineResult.propTypes = {
  results: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};
