import styled from 'styled-components';
import React, { Component } from 'react';
import copy from 'copy-to-clipboard';

export const AppHeader = styled.div`
   {
    z-index: 99999;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 84%
    );
  }
`;

export const AppHeaderLogo = styled.img.attrs((props) => ({
  hidden: props.hidden || false,
  displayed: props.displayed,
}))`
   {
    width: 143px;
    margin-right: 20px;
    transition: opacity 0.2s ease;
    opacity: ${(props) => (props.hidden ? '0' : '1')};
    display: ${(props) => (props.displayed ? 'block' : 'none')};

    @media (max-height: 411px) {
      width: 6em;
    }
  }
`;

export const AppHeaderRight = styled.div`
   {
    display: flex;
    margin-right: 10px;
    font-weight: bold;
  }
`;

export const AppHeaderButtons = styled.div`
   {
    display: none;
    cursor: pointer;
    color: #ffffff;
    font: Semibold 22px/27px Proxima Nova;

    @media (min-width: 1025px) {
      display: flex;
      margin-right: 20px;
      padding: 20px;
    }
    &:hover {
      background: rgba(0, 153, 255, 0.59);
    }
  }
`;

export const AppHeaderLink = styled.a.attrs((props) => ({
  menuOpen: props.menuOpen || false,
}))`
   {
    text-decoration: none
    display: none;
    cursor: pointer;
    color: #ffffff;
    font: Semibold 22px/27px Proxima Nova;

    @media (min-width: 1025px) {
      display: flex;
      padding: 20px;
    }
    &:hover , &:active {
      color: rgba(0, 153, 255, 0.59)
    }
  }
`;

export const AppHeaderLinkPrimary = styled.a.attrs((props) => ({
  menuOpen: props.menuOpen || false,
}))`
   {
    text-decoration: none
    display: none;
    cursor: pointer;
    color: #ffffff;
    font: Semibold 22px/27px Proxima Nova;
    border-radius: 4px;

    transition: background-color 0.3s;
    transition-property:  background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;

    @media (min-width: 1025px) {
      display: flex;
      padding: 20px;
    }
    &:hover , &:active {
      color: white;
      background: rgba(0, 153, 255, 0.59);
    }
  }
`;

const AppHeaderDropdown = styled.div`
   {
    position: absolute;
    // background: rgba(0, 153, 255, 0.59);
    font-weight: normal;
  }
`;

// TODO: move to FC - no time now... will kill this with fire later
// and do it correctly with `refs` .. sorry for this insolence
export class AppHeaderShareMenu extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    if (!this.dropdownMenu.contains(event.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });
    }
  }

  render() {
    return (
      <div>
        <AppHeaderLink menuOpen={this.state.showMenu} onClick={this.showMenu}>
          Share this page
        </AppHeaderLink>

        {this.state.showMenu ? (
          <AppHeaderDropdown
            ref={(element) => {
              this.dropdownMenu = element;
            }}
          >
            <AppHeaderLink onClick={() => copy(window.location.href)}>
              Copy link
            </AppHeaderLink>
            <AppHeaderLink
              href={`mailto:?Subject=Anyline%20For%20Websites&body=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Share on email
            </AppHeaderLink>
          </AppHeaderDropdown>
        ) : null}
      </div>
    );
  }
}
