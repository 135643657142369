import React, { createContext, useState } from "react";
import PropTypes from 'prop-types';

export const ResultExpandContext = createContext({
  resultExpanded: false,
  setResultExpanded: value => {}
});

export const ResultExpandProvider = props => {
  const [resultExpanded, setResultExpanded] = useState(false);

  return (
    <ResultExpandContext.Provider
      value={{
        resultExpanded,
        setResultExpanded: expanded => setResultExpanded(expanded)
      }}
    >
      {props.children}
    </ResultExpandContext.Provider>
  );
};

ResultExpandProvider.propTypes = {
  children: PropTypes.any,
}
