import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UseCaseContext } from '../context/UseCaseContext';
import { ScanResultContext } from '../context/ScanResultContext';
import { useCases } from './Utils';
import "./toggle-menu.css"

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 8px 0;
`;

const SectionLink = styled.a`
    text-decoration: none;
    text-align: left;
    letter-spacing: 0;
    color: #ffffff;
    width: 100%;
    padding-left: 8px;
    padding-bottom: 5px;
    padding-top: 5px;
    &:active {
      color: rgba(0, 153, 255, 0.52);
    }
`;

const UseCaseContainerOuter = styled.div`
  position: absolute;
  left: ${(props) => props.menuIsOpen ? 0 : '-250px'};
  top: 0;
  display: flex;
  color: #ffffff;
  background: ${(props) => props.menuIsOpen ? '#000000cc' : 'transparent'};
  width: 300px;
  height: 100%;
  transition: 0.3s left, 0.3s background;
  z-index: 99999;
`;

const UseCaseContainerInner = styled.div`
  top: 0;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
  padding: 20px 8px 8px 8px;
  overflow-y: auto;
`;


const MenuToggle = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  right:  0px;
  top: 0;  // calc(50% - 25px);
  z-index: 99999;
`;

const BetaHeader = styled.div`
  display: block;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
`;

const UseCaseButton = styled.div.attrs((props) => {
  return ({
    isSelected: props.isSelected || false,
  })
})`
    flex: 1;
    display: flex;
    font-size: 14px;
    cursor: pointer;
    padding: 3px 10px;
    border-radius: 2px;
    align-items: center;
    background-color: ${(props) =>
      props.isSelected ? 'rgba(0, 153, 255, 0.52);' : 'transparent'};
    &:hover {
      color: ${(props) => (props.isSelected ? '#fff' : '#0099FF')};
    }
`;

export const UseCaseSelector = ({ showMulitBarcodeToggle, setShowMulitBarcodeToggle, showTroubleScanningBarcodeInfo }) => {
  const { currentUseCase, setCurrentUseCase } = useContext(UseCaseContext);
  const { clearScanResult, isResult } = useContext(ScanResultContext);
  const [menuIsOpen, setMenuIsOpen] = useState(true);
  const [menuIcon, setMenuIcon] = useState(document.createElement('div'));

  useEffect(() => {
    let icon = document.querySelector('.menu_icon');
    setMenuIcon(icon);
    icon.classList.toggle('clicked');

    icon.addEventListener('click', () => {
      icon.classList.toggle('clicked');
    });

    setShowMulitBarcodeToggle(false);
  }, [setShowMulitBarcodeToggle]);

  return (
    <UseCaseContainerOuter menuIsOpen={menuIsOpen}>
      <MenuToggle onClick={() => setMenuIsOpen(!menuIsOpen)}>
        <div className="menu_icon">
          <span className="one"></span>
          <span className="two"></span>
          <span className="three"></span>
        </div>
      </MenuToggle>
      <UseCaseContainerInner>
        <BetaHeader>Presets</BetaHeader>
        <div>
          {useCases
            .filter((el) => !el.isBeta)
            .map((useCase) => {
              return (
                <UseCaseButton
                  key={useCase.id}
                  onClick={() => {
                    if (showMulitBarcodeToggle) {
                      setShowMulitBarcodeToggle(false);
                    }

                    if (useCase.id === 'all_barcode_formats') {
                      showTroubleScanningBarcodeInfo();
                    }

                    setCurrentUseCase(useCase.id);
                    if (isResult) clearScanResult();
                    setMenuIsOpen(false);
                    menuIcon.classList.toggle('clicked');
                  }}
                  isSelected={currentUseCase === useCase.id}
                >
                  {useCase.text}
                </UseCaseButton>
              );
            })}
        </div>
        <Separator></Separator>
        <BetaHeader>Links</BetaHeader>
        <SectionLink href="https://anyline.com/samples" target="_blank" rel="noopener noreferrer">
          Example Sheets
        </SectionLink>
        <SectionLink href="https://ocr.anyline.com/contact" target="_blank" rel="noopener noreferrer">
          Contact sales
        </SectionLink>
      </UseCaseContainerInner>
    </UseCaseContainerOuter>
  );
};

UseCaseSelector.propTypes = {
  showMulitBarcodeToggle: PropTypes.bool.isRequired,
  setShowMulitBarcodeToggle: PropTypes.func.isRequired,
  showTroubleScanningBarcodeInfo: PropTypes.func.isRequired,
};
