import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ScanContext = createContext({
  startScanTime: 0,
  setStartScanTime: (useCase) => {},
});

export const ScanProvider = (props) => {
  const [startScanTime, setStartScanTime] = useState(0);

  return (
    <ScanContext.Provider
      value={{
        startScanTime,
        setStartScanTime: (timeInMs) => {
          if (timeInMs > 6900) {
            alert('Nothing to scan!!')
          }
          setStartScanTime(timeInMs)
        },
      }}
    >
      {props.children}
    </ScanContext.Provider>
  );
};

ScanProvider.propTypes = {
  children: PropTypes.any,
}
