import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DesktopIcon from '../icons/desktop.png';
import MobileIcon from '../icons/mobile.png';
import DeleteCross from '../icons/delete-cross.png';
import { isValidBrowser } from './Utils';
import { detect } from 'detect-browser';
import Button from 'antd/lib/button';

const FlexDiv = styled.div`
  display: flex;
`;

const StyledDeviceIcons = styled.img`
   {
    margin-right: 0.3em;
    height: 24px;
    width: 24px;
  }
`;

const StyledDeviceBlock = styled.div`
   {
    display: flex;
    justify-content: left;
    flex-direction: column;
  }
`;

const StyledFirstHeader = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 140.62%;
  color: #0099FF;
  text-align: center;
`;

const StyledSecondHeader = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140.62%;
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
  align-self: center;
  background: #007ACC;
  border-radius: 5px;
  color: white;
`;

const StyledContainer = styled.div`
display: flex;
flex-direction: column;
margin: auto;
opacity: 0.95;
color: #333333;
background: #FFFFFF;
border: 10px solid #0099FF;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
padding: 18px;
width: 50%;
margin-top: 10%;
@media only screen and (max-width: 768px) {
  margin-left: 5%;
  width: 90%; 
}
`;

const StyledCheckContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
`;

const StyledCheckText = styled.p`
  margin-left: 0;
`;

const StyledScanList = styled.li`
  font-size: 14px;
  line-height: 120%;
  color: #333333;
`;

const StyledScanStepList = styled.li`
  font-size: 16px;
  line-height: 130%;
  color: #333333;
`;

const LeftPaddedLine = styled.div`
   {
    margin-left: 1em;
  }
`;

const UnsupportedBrowser = (props) => {
  const { name: browser, version, os: operatingSystem } = props.browserInfo;

  return (
    <>
      <p>Anyline Web SDK currently works on:</p>
      <FlexDiv>
        <StyledDeviceIcons alt="Mobile" src={MobileIcon} />
        <StyledDeviceBlock>
          Mobile - only devices released after 2016: <br />
          <LeftPaddedLine>iOS: Safari web browser</LeftPaddedLine>
          <LeftPaddedLine>Android: Google Chrome and Firefox web browser</LeftPaddedLine>
          <br />
        </StyledDeviceBlock>
      </FlexDiv>
      <FlexDiv>
        <StyledDeviceIcons alt="Desktop" src={DesktopIcon} /> Desktop - Google
        Chrome and Firefox web browser
      </FlexDiv>
      <FlexDiv style={{ fontWeight: '600', paddingTop: '1em' }}>
        <FlexDiv>
          <StyledDeviceIcons
            style={{ marginRight: '1em' }}
            alt="unsuccessful"
            src={DeleteCross}
          />
          <FlexDiv style={{ marginTop: '0.1em' }}>
            Unsupported browser {`( ${browser} - ${version} - ${operatingSystem})`}
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </>
  );
};

UnsupportedBrowser.propTypes = {
  browserInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    os: PropTypes.string.isRequired,
  }).isRequired,
};


const DeclarationOfConsent = (props) => {
  const [accepted] = useState(true);

  return (
    <>
      <p>Anyline Web SDK Demo supports the following data capture modules:</p>
      <ol>
        <StyledScanList><b>Tire</b> (Tire Size, DOT, Tire ID)</StyledScanList>
        <StyledScanList><b>License Plates</b> (European countries and US states)</StyledScanList>
        <StyledScanList><b>VIN</b> (Vehicle Identification Number)</StyledScanList>
        <StyledScanList><b>Meter</b> (Analog meters, digital meters and dial meters)</StyledScanList>
        <StyledScanList><b>Barcode</b> (1D, 2D and stacked linear barcodes)</StyledScanList>
        <StyledScanList><b>IDs</b> (Documents with MRZ, driving licenses (AT/DE))</StyledScanList>
      </ol>
      <p>
        To learn more about our products, please go to our{' '}
        <a href="https://anyline.com/products" target="_blank" rel="noopener noreferrer">Products Page.</a>
      </p>
      
      <h2>Note</h2>
      <StyledCheckContainer>
        <StyledCheckText>
          Please note that this application is going to process visual data on your device. 
          In accordance with local data protection regulations you may require a legal basis 
          for processing such data (e.g. legitimate interest, consent declaration, etc) and 
          it is your responsibility to comply with local data protection regulations. 
          If personal data is transmitted in connection with the use of this application we 
          will process this personal data on your behalf as (sub-) data processor in terms of the GDPR. 
          In this case, you are subject to our <a href='https://ocr.anyline.com/datasheet' target='_blank' rel='noopener noreferrer'><b>data processing agreement</b></a>.{' '}Our current 
          <a href='https://anyline.com/imprint-and-legal' target='_blank' rel='noopener noreferrer'><b>Terms and Conditions</b></a> apply to your use of the application.
        </StyledCheckText>
      </StyledCheckContainer>

      <div style={{ textAlign: 'center' }}>
        <StyledButton onClick={() => props.setContinued(true)} disabled={!accepted}>Accept</StyledButton>
      </div>
    </>
  );
};

DeclarationOfConsent.propTypes = {
  setContinued: PropTypes.func.isRequired,
};

const WelcomeText = (props) => {
  return (
    props.validBrowser ? (
      <DeclarationOfConsent setContinued={props.setContinued} />
    ) : (
      <UnsupportedBrowser browserInfo={props.browserInfo} />
    )
  );
};

WelcomeText.propTypes = {
  validBrowser: PropTypes.bool.isRequired,
  setContinued: PropTypes.func.isRequired,
  browserInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    os: PropTypes.string.isRequired,
  }).isRequired,
};


const ScanInstructionText = () => (
  <div>
    <h2>Let's scan!</h2>
    <ol>
      <StyledScanStepList>Place the item you want to scan within the scan frame on the screen</StyledScanStepList>
      <StyledScanStepList>Hold still and wait for the result</StyledScanStepList>
      <StyledScanStepList>The result will show right below the scan frame</StyledScanStepList>
    </ol>
  </div>
);

export const SplashScreen = (props) => {
  const browserInfo = detect();
  const [continued, setContinued] = useState(false);
  const validBrowser = isValidBrowser(browserInfo);
  const needsSafari = browserInfo.os === 'iOS';

  return (
    <StyledContainer>
      <StyledFirstHeader>Anyline<br></br> Web SDK Demo</StyledFirstHeader>
      <StyledSecondHeader>
        Thank you for your interest in our browser-based scanning solution.
      </StyledSecondHeader>
      <div>
        {!continued && (
          <WelcomeText validBrowser={validBrowser} browserInfo={browserInfo} setContinued={setContinued} />
        )}
        {continued && validBrowser && <ScanInstructionText />}
      </div>

      <div>
        {continued && validBrowser && (
          <>
            <h2>Enjoy your scanning experience!</h2>
            <div style={{ textAlign: 'center' }}>
              <StyledButton onClick={props.handleClose}>
                Start scanning
            </StyledButton>
            </div>
          </>
        )}
        {!continued &&
          !validBrowser &&
          (!needsSafari ? (
            <a
              href="https://www.google.com/intl/en_us/chrome/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <br />
              Use a recommended browser
            </a>
          ) : (
              <StyledSecondHeader>Please use Safari on iOS devices.</StyledSecondHeader>
            ))}
      </div>
    </StyledContainer>
  );
};

SplashScreen.propTypes = {
  handleClose: PropTypes.func,
};

