import styled from 'styled-components';

export const AppFooter = styled.div`
   {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding-top: 15px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 30%,
      rgba(0, 0, 0, 0) 100%
    );
    font-size: 12px;
    flex-direction: column;
    @media (min-width: 641px) {
      flex-direction: row;
    }
  }
`;

export const AppFooterCopyRight = styled.span`
   {
    color: #fff;
    margin-bottom: 0;
  }
`;

export const AppFooterLink = styled.a`
  display: flex;
  color: #0099ff;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;
  margin-right: 8px;
`;

export const AppFooterFeedbackIcon = styled.img`
   {
    width: 24px;
    height: 23px;
    margin-right: 3px;
  }
`;
