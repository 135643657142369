import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'antd/lib/button';

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const StyledFirstHeader = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 140.62%;
  color: #0099FF;
`;

const StyledSecondHeader = styled.h2`
  font-style: normal;
  font-size: 24px;
  line-height: 140.62%;
`;

const StyledButton = styled(Button)`
  margin-top: 10px;
  align-self: center;
  background: #007ACC;
  border-radius: 5px;
  color: white;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  opacity: 0.95;
  color: #333333;
  background: #FFFFFF;
  border: 10px solid #0099FF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 18px;
  width: 50%;
  margin-top: 10%;
  @media only screen and (max-width: 768px) {
    margin-left: 5%;
    width: 90%; 
  }
`;

export const InfoModal = (props) => {
  return (
    <StyledContainer>
      <StyledFirstHeader>Having trouble scanning?</StyledFirstHeader>
      <StyledSecondHeader>
        If you need any help please have a look at our documentation or contact us directly.
      </StyledSecondHeader>
      <StyledSecondHeader>
        <a href="https://documentation.anyline.com/web-sdk-component/latest/index.html" target="_blank" rel="noopener noreferrer">
          Full Documentation
        </a>
      </StyledSecondHeader>
      <Row>
        <StyledButton onClick={() => props.onClose(false)}>Close</StyledButton>
        <StyledButton onClick={() => window.open('https://anyline.com/request/contact', '_blank', 'noopener')}>Contact us</StyledButton>
      </Row>
    </StyledContainer>
  );
};

InfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
