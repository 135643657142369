import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const MobileFooterContext = createContext({
  isExpanded: false,
  setIsExpanded: (isExpanded) => {},
  delayedHidden: false,
  setDelayedHidden: (value) => {},
  delayedShown: false,
  setDelayedShown: (value) => {},
  resetFlags: () => {},
});

export const MobileFooterProvider = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [delayedHidden, setDelayedHidden] = useState(false);
  const [delayedShown, setDelayedShown] = useState(true);

  return (
    <MobileFooterContext.Provider
      value={{
        isExpanded,
        setIsExpanded: (useCase) => setIsExpanded(useCase),
        delayedHidden,
        delayedShown,
        setDelayedHidden: (state) => {
          setTimeout(() => setDelayedHidden(state), 500);
        },
        setDelayedShown: (state) => {
          setTimeout(() => setDelayedShown(state), 500);
        },
        resetFlags: () => {
          setIsExpanded(false);
          setDelayedHidden(false);
          setDelayedShown(true);
        },
      }}
    >
      {props.children}
    </MobileFooterContext.Provider>
  );
};

MobileFooterProvider.propTypes = {
  children: PropTypes.any,
}
