import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const UseCaseContext = createContext({
  currentUseCase: 'meter',
  setCurrentUseCase: (useCase) => {},
});

export const UseCaseProvider = (props) => {
  const [currentUseCase, setCurrentUseCase] = useState('meter');

  return (
    <UseCaseContext.Provider
      value={{
        currentUseCase,
        setCurrentUseCase: (useCase) => setCurrentUseCase(useCase),
      }}
    >
      {props.children}
    </UseCaseContext.Provider>
  );
};

UseCaseProvider.propTypes = {
  children: PropTypes.any,
}
