import styled from "styled-components";

export const AppLower = styled.div`
   {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;
